import { useCallback, useState } from 'react'
import axios from 'axios'

const API_CONFIG_ENDPOINT = process.env.REACT_APP_API_CONFIG_ENDPOINT

axios.defaults.baseURL = API_CONFIG_ENDPOINT

const useConfig = () => {

  const [isLoading, setIsLoading] = useState(false)

  const getKioskConfig = useCallback(async (configType) => {
    setIsLoading(true)

    let url = ''

    switch (configType) {
      case 'V3':
        url = `v3/kiosk-config.json`
        break
      case 'SETTING_PROGRAM':
        url = `v3/setting_program.json`
        break
      default:
        url = ''
        break
    }

    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      }).then((res) => res?.data).catch((error) => { return false })
      return response
    } finally {
      setIsLoading(false)
    }
  }, [])

  return { isLoading, getKioskConfig }
}

export default useConfig
