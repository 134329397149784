import React, { useCallback, useState, Fragment, useEffect, useRef, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ColdIcon from '../../assets/images/cold.png'
import WarmIcon from '../../assets/images/warm.png'
import HotIcon from '../../assets/images/hot.png'
import PromptpayIcon from '../../assets/images/prompt-pay.png'
import TruemoneyIcon from '../../assets/images/true-money.png'
import LinepayIcon from '../../assets/images/line-pay.png'
import { useParams } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { Turnstile } from '@marsidev/react-turnstile'
import MachineDetailSection from '../../components/MachineDetailSection'
import TextField from '../../components/TextField'
import Button from '../../components/Button'
import {
  MdLocalPhone,
  MdClose,
  MdAdd,
  MdRemove,
  MdConfirmationNumber,
  MdOutlineExpandLess,
  MdOutlineExpandMore
} from 'react-icons/md'
import OceanWave from '../../components/OceanWave'
import MachineNotIDLE from '../../components/MachineNotIDLE'
import MachineNotFound from '../../components/MachineNotFound'
import useBranch from '../../hooks/useBranch'
import useTransaction from '../../hooks/useTransaction'
import ModalPayment from '../../components/payments/ModalPayment'
import { useNavigate } from 'react-router-dom'
import CCFavicon from '../../assets/images/cc-favicon.ico'
import WngFavicon from '../../assets/images/wng-favicon.ico'
import useConfig from '../../hooks/useConfig'

const Page = () => {
  const defaultTime = 28
  const navigate = useNavigate()
  const { branchid, machineid } = useParams()
  const { isLoading, getBranchAndMachine, getMachineStatus, getValidateBranchByCode } = useBranch()
  const { isLoading: isLoadingConfig, getKioskConfig } = useConfig()
  const { createTransaction } = useTransaction()

  const [kioskConfig, setKioskConfig] = useState(null)
  const [settingProgram, setSettingProgram] = useState([])

  const [selectedTime, setSelectedTime] = useState(defaultTime)
  const [machineData, setMachineData] = useState(null)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [extraTime, setExtraTime] = useState(0)
  const [extraPriceMachine, setExtraPriceMachine] = useState(0)
  const [isNotFound, setNotFound] = useState(false)
  const ref = useRef(null)
  const [token, setToken] = useState(null)
  const [coupon, setCoupon] = useState('')
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')
  const [useCoupon, setUseCoupon] = useState(false)
  const [provider, setProvider] = useState('')
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [transaction, setTransaction] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)
  const [errorSelectedProgram, setErrorSelectedProgram] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [incExtendDry, setIncExtendDry] = useState(false)
  const [branchValidate, setBranchValidate] = useState(null)
  const [paymentColumns, setPaymentColumns] = useState([])

  const color = {
    LOW: 'bg-[#b9eaff]',
    MED: 'bg-[#f9c3af]',
    HIGH: 'bg-[#ff889c]'
  }

  const updateFavicon = iconUrl => {
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = iconUrl

    // Clear any existing favicon if present
    document.head.querySelector("link[rel='icon']")?.remove()

    // Append the new favicon link
    document.head.appendChild(link)
  }

  const getDryerData = useCallback(
    async (branchid, machineid) => {
      const data = await getBranchAndMachine(branchid, machineid)
      if (data?.branch && data?.machine) {
        setMachineData(data)

        const config = await getValidateBranchByCode(data.branch.code)

        const items = []
        if (config?.show_promptpay) items.push('PROMPTPAY')
        if (config?.show_truemoney) items.push('TRUEMONEY')
        if (config?.show_linepay) items.push('LINEPAY')
        setPaymentColumns(items)
        setBranchValidate(config)

        if (config?.is_codeclean_logo) {
          updateFavicon(CCFavicon)
        } else {
          updateFavicon(WngFavicon)
        }

        if (data?.machine && data?.machine?.type !== 'DRYER') {
          setNotFound(!isNotFound)
        }

        const responseMachine = await getMachineStatus(branchid, machineid)

        const machineStatusList = responseMachine?.data || []

        const updateStatus = machineStatusList.find(item => item.machine_id === machineid)

        if (updateStatus && updateStatus?.status !== 'DISCONNECTED') {
          setMachineData(prevState => ({
            ...prevState,
            machine: {
              ...prevState?.machine,
              ...updateStatus
            }
          }))
        } else {
          setMachineData(prevState => ({
            ...prevState,
            machine: {
              ...prevState?.machine,
              machine_id: machineid,
              status: 'DISCONNECTED',
              current_transaction: '',
              temperature: 0,
              remaining_minute: 0,
              water_level: 0,
              current_program: 0,
              current_operation_step: 0,
              coin_in_cash_box: 0,
              remaining_second: 0,
              version: 0,
              updated_at: null
            }
          }))
        }
      } else {
        setNotFound(true)
      }
    },
    [getBranchAndMachine]
  )

  const fetchKioskConfig = useCallback(async () => {
    const response = await getKioskConfig('V3')
    if (response) {
      setKioskConfig(response)
    } else {
      setKioskConfig({
        enable_wash: true,
        enable_dry: true,
        enable_item: true,
        enable_extendry: true,
        enable_cash: true,
        enable_qr: true,
        enable_linepay: true,
        enable_tmn: true,
        enable_coupon: true,
        theme: 'common'
      })
    }
  }, [getKioskConfig])

  const fetchSettingProgram = useCallback(async () => {
    const response = await getKioskConfig('SETTING_PROGRAM')
    if (response) {
      setSettingProgram(response)
    } else {
      setSettingProgram([])
    }
  }, [])

  const disableProgram = useCallback((program) => {
    if (!settingProgram || !branchid) {
      return false;
    }
    const settingProgram_ = settingProgram.find(
      (setting) => setting.branch_id === branchid
    );
    return settingProgram_?.program_disabled?.includes(program) || false;
  }, [settingProgram, branchid]);

  const getOptionImage = option => {
    if (option === 'MED') {
      return (
        <>
          <img src={WarmIcon} width={10} alt="warm-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">กลาง</span>
        </>
      )
    } else if (option === 'HIGH') {
      return (
        <>
          <img src={HotIcon} width={10} alt="hot-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">สูง</span>
        </>
      )
    } else if (option === 'LOW') {
      return (
        <>
          <img src={ColdIcon} width={10} alt="cold-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">ต่ำ</span>
        </>
      )
    }
  }

  const onClickExtraTime = action => {
    if (action === 'down') {
      setExtraTime(extraTime - 5)
    } else if (action === 'up') {
      setExtraTime(extraTime + 5)
    }
  }

  useEffect(() => {
    if (branchid && machineid) {
      const fetchData = async () => {
        await fetchKioskConfig()
        await fetchSettingProgram()
        await getDryerData(branchid, machineid)
      }
      fetchData()
    }
    return () => {
      setSelectedProgram(null)
      setSelectedTime(defaultTime)
      setExtraTime(0)
      setExtraPriceMachine(0)
      setNotFound(false)
      setCoupon('')
      setCustomerPhoneNumber('')
      setUseCoupon(false)
      setTransaction(null)
      setModalLoading(false)
      setRefresh(false)
      setErrorSelectedProgram(false)
      setIncExtendDry(false)
    }
  }, [branchid, machineid])

  useEffect(() => {
    if (errorSelectedProgram) {
      setTimeout(() => {
        setErrorSelectedProgram(false)
      }, 4000)
    }
  }, [errorSelectedProgram])

  useEffect(() => {
    if (refresh) {
      navigate('/thank-you')
    }
  }, [refresh])

  useEffect(() => {
    let extraPrice = extraTime * process.env.REACT_APP_EXTRA_DRYER_PRICE
    setExtraPriceMachine(extraPrice)

    let calculateTime = machineData?.machine?.status === 'RUNNING' ? extraTime : defaultTime + extraTime
    setSelectedTime(calculateTime)
  }, [extraTime, machineData?.machine?.status])

  const onChangeCoupon = e => {
    let mask = 'XXXX-XXXX'
    let maskChar = '-'
    const value = e.target.value
    let newValue = value

    if (value.endsWith(maskChar) && e.nativeEvent.inputType === 'deleteContentBackward') {
      newValue = value.slice(0, -1) // Remove the maskChar
    } else {
      if (mask[value.length] === maskChar) {
        newValue += maskChar
      }
    }

    setCoupon(newValue.toUpperCase())
  }

  const onChangeCustomerPhonenumber = e => {
    const result = e.target.value.replace(/\D/g, '')
    setCustomerPhoneNumber(result)
  }

  const onSetUseCoupon = () => {
    setUseCoupon(!useCoupon)
    setCoupon('')
  }

  const onCreateTransaction = async (isExtendDry = false) => {
    setModalLoading(true)
    let body = {
      branch_id: machineData?.branch?._id,
      machine_id: machineData?.machine?._id,
      is_offline: false,
      program: selectedProgram?.program || 'HOT',
      coupon_code: coupon || null,
      customer_phone_number: customerPhoneNumber || '',
      transaction_type: 'EXPRESS',
      extend_dry: extraPriceMachine || 0,
      is_extend_dry: isExtendDry
    }

    try {
      const response = await createTransaction(body)
      if (response?._id) {
        setTransaction(response)
      }
      throw response
    } catch (err) {
    } finally {
      setModalLoading(false)
    }
  }

  const onShowModal = async (type, isExtendDry = false) => {
    if (!selectedProgram && !isExtendDry) {
      setErrorSelectedProgram(true)
      return
    }

    await onCreateTransaction(isExtendDry)

    setIncExtendDry(isExtendDry)

    switch (type) {
      case 'PROMPTPAY':
        setProvider('promptpay')
        setShowPaymentModal(true)
        break
      case 'TRUEMONEY':
        setProvider('truemoney')
        setShowPaymentModal(true)
        break
      case 'LINEPAY':
        setProvider('linepay')
        setShowPaymentModal(true)
        break
      default:
        setProvider('')
        setShowPaymentModal(false)
        break
    }
  }

  const isNotRunning = status => {
    if (status !== 'RUNNING') {
      return true
    } else {
      return false
    }
  }

  const enableDry = useMemo(() => {
    return kioskConfig?.enable_dry
  },[kioskConfig])

  const enableExtendDry = useMemo(() => {
    return kioskConfig?.enable_extendry
  }, [kioskConfig])

  const enableCoupon = useMemo(() => {
    return kioskConfig?.enable_coupon
  }, [kioskConfig])

  const enableQr = useMemo(() => {
    return kioskConfig?.enable_qr
  }, [kioskConfig])

  const enableLinepay = useMemo(() => {
    return kioskConfig?.enable_linepay
  }, [kioskConfig])

  const enableTruemoney = useMemo(() => {
    return kioskConfig?.enable_tmn
  }, [kioskConfig])

  return (
    <div className="relative max-w-4xl w-full h-[calc(100vh_-_160px)]">
      {isLoading || isLoadingConfig ? (
        <div className="w-full h-screen flex items-center justify-center">
          <span className="loading loading-ring loading-lg text-orange-400"></span>
        </div>
      ) : (
        <>
          {!isNotFound ? (
            <>
              {machineData?.machine?.status !== 'DISCONNECTED' && enableDry && (
                <MachineDetailSection
                  machineType="DRY"
                  machine={machineData}
                  selectedProgram={selectedProgram}
                  extraPriceMachine={extraPriceMachine}
                />
              )}
              {machineData?.machine?.status === 'IDLE' && enableDry ? (
                <>
                  <div className="absolute z-10 w-full p-6 flex flex-col self-stretch gap-6">
                    <div
                      className={`flex flex-col self-stretch gap-2 ${errorSelectedProgram ? 'p-2 animate-error-bg-pulse rounded-lg' : ''
                        }`}>
                      <div className="text-zinc-800 text-sm font-medium">เลือกอุณหภูมิ</div>
                      <div className="flex gap-2 self-stretch">
                        {machineData?.machine?.pricings &&
                          machineData?.machine?.pricings.filter((pricing) => {
                            return !disableProgram(pricing.program.toUpperCase());
                          }).map((item, index) => (
                            <div
                              className={`w-full cursor-pointer rounded-md py-2.5 px-3 border ${selectedProgram?.program === item.program ? color[item.program] : 'bg-gray-50'
                                } items-center justify-center gap-[10px] flex`}
                              key={`washing-machine-${item.program}`}
                              onClick={() => setSelectedProgram(item)}>
                              {getOptionImage(item.program)}
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="flex flex-col self-stretch gap-2">
                      <div className="text-zinc-800 text-sm font-medium">เลือกเวลาอบ</div>
                      <div className="flex items-center justify-between self-stretch">
                        <Button
                          label={<MdRemove className="w-4 h-4 text-dark" />}
                          className="btn btn-default hover:btn-warning"
                          disabled={extraTime === 0 || !enableExtendDry}
                          onClick={() => {
                            if (extraTime === 0) {
                              return
                            }
                            onClickExtraTime('down')
                          }}
                        />
                        {!enableExtendDry ? <span className='text-red-500 text-center text-sm'>ปิดใช้งานเพิ่มเวลาอบชั่วคราว</span> :
                          <div className="flex flex-col gap-2 items-center text-gray-900 text-sm font-normal">
                            <div>{extraTime || 0} นาที</div>
                            <div>{extraPriceMachine || 0} บาท</div>
                          </div>
                        }
                        <Button
                          label={<MdAdd className="w-4 h-4 text-dark" />}
                          className="btn btn-default hover:btn-warning"
                          disabled={extraTime === 60 || !enableExtendDry}
                          onClick={() => {
                            if (extraTime === 60) {
                              return
                            }
                            onClickExtraTime('up')
                          }}
                        />
                      </div>
                      <div className="text-neutral-600 text-xs font-medium">
                        เวลาอบทั้งหมด : <span className="text-red-500 text-xs font-medium">{selectedTime} นาที</span>
                      </div>
                    </div>

                    {branchValidate?.is_codeclean_logo && (<div className="flex flex-col self-stretch gap-2">
                      <div className="text-zinc-800 text-sm font-medium">เบอร์โทรศัพท์</div>
                      <TextField
                        prependIcon={<MdLocalPhone className="w-4 h-4 text-gray-500" />}
                        placeholder="เบอร์โทรศัพท์"
                        value={customerPhoneNumber}
                        onChange={e => onChangeCustomerPhonenumber(e)}
                        maxLength={10}
                        appendIcon={
                          <MdClose className="w-4 h-4 text-gray-500" onClick={() => setCustomerPhoneNumber('')} />
                        }
                      />
                    </div>)}

                    {branchValidate?.enable_reward && (
                      <>
                        {enableCoupon && (
                          <div className="flex flex-col self-stretch gap-2">
                            <div className="w-fit">
                              <div
                                className="text-zinc-800 text-sm font-medium flex gap-1 items-center cursor-pointer"
                                onClick={() => onSetUseCoupon()}>
                                <MdConfirmationNumber className="w-4 h-4 text-gray-500" /> ใช้งานคูปองส่วนลด
                                <MdOutlineExpandLess className="w-4 h-4 text-gray-500" hidden={useCoupon} />
                                <MdOutlineExpandMore className="w-4 h-4 text-gray-500" hidden={!useCoupon} />
                              </div>
                            </div>
                            {useCoupon ? (
                              <div className="fade-in">
                                <div className="text-zinc-800 text-sm font-medium">รหัสคูปองส่วนลด</div>
                                <TextField
                                  prependIcon={<MdConfirmationNumber className="w-4 h-4 text-gray-500" />}
                                  placeholder="รหัสคูปองส่วนลด"
                                  value={coupon}
                                  onChange={e => onChangeCoupon(e)}
                                  maxLength={9}
                                  appendIcon={<MdClose className="w-4 h-4 text-gray-500" onClick={() => setCoupon('')} />}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </>
                    )}

                    <div className="flex flex-col self-stretch gap-2">
                      <div className="text-zinc-800 text-sm font-medium">เลือกช่องทางการชำระเงิน</div>
                      <div className={`grid grid-cols-${paymentColumns.length} gap-2`}>
                        {branchValidate?.show_promptpay && (
                          <div className="relative col-span-auto">
                            <Button
                              className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                              label={<img src={PromptpayIcon} height={20} alt="promptpay-payment" />}
                              onClick={() => onShowModal('PROMPTPAY')}
                              disabled={modalLoading || !enableQr}
                              loading={modalLoading}
                              crossedOutText={!enableQr ? 'ปิดใช้งานชั่วคราว' : null}
                            />
                          </div>
                        )}

                        {branchValidate?.show_truemoney && (
                          <div className="relative col-span-auto">
                            <Button
                              className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                              label={<img src={TruemoneyIcon} height={20} alt="truemoney-payment" />}
                              onClick={() => onShowModal('TRUEMONEY')}
                              disabled={modalLoading || !enableTruemoney}
                              loading={modalLoading}
                              crossedOutText={!enableTruemoney ? 'ปิดใช้งานชั่วคราว' : null}
                            />
                          </div>
                        )}

                        {branchValidate?.show_linepay && (
                          <div className="relative col-span-auto">
                            <Button
                              className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                              label={<img src={LinepayIcon} height={20} alt="linepay-payment" />}
                              onClick={() => onShowModal('LINEPAY')}
                              disabled={modalLoading || !enableLinepay}
                              loading={modalLoading}
                              crossedOutText={!enableLinepay ? 'ปิดใช้งานชั่วคราว' : null}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {machineData?.machine?.status === 'RUNNING' && machineData?.machine?.remaining_minute > 10 ? (
                    <>
                      <div className="absolute z-10 w-full p-6 flex flex-col self-stretch gap-6">
                        <div className="flex flex-col gap-1">
                          <div className="text-center text-error text-xl font-medium">เครื่องอบผ้ากำลังใช้งานอยู่</div>
                          <div className="text-center text-error text-sm font-medium">โปรดรอซักครู่</div>
                          <div className="text-center text-error text-xs font-medium">หรือเพิ่มเวลาอบผ้าด้านล่าง</div>
                        </div>

                        <div className="flex flex-col self-stretch gap-2">
                          <div className="text-zinc-800 text-sm font-medium">เพิ่มเวลาอบผ้า</div>
                          <div className="flex items-center justify-between self-stretch">
                            <Button
                              label={<MdRemove className="w-4 h-4 text-dark" />}
                              className="btn btn-default hover:btn-warning"
                              disabled={extraTime === 0}
                              onClick={() => {
                                if (extraTime === 0) {
                                  return
                                }
                                onClickExtraTime('down')
                              }}
                            />
                            <div className="flex flex-col gap-2 items-center text-gray-900 text-sm font-normal">
                              <div>{extraTime || 0} นาที</div>
                              <div>{extraPriceMachine || 0} บาท</div>
                            </div>
                            <Button
                              label={<MdAdd className="w-4 h-4 text-dark" />}
                              className="btn btn-default hover:btn-warning"
                              disabled={extraTime === 60}
                              onClick={() => {
                                if (extraTime === 60) {
                                  return
                                }
                                onClickExtraTime('up')
                              }}
                            />
                          </div>
                          <div className="text-neutral-600 text-xs font-medium">
                            เวลาอบทั้งหมด :{' '}
                            <span className="text-red-500 text-xs font-medium">{selectedTime} นาที</span>
                          </div>
                        </div>

                        {/* <Turnstile
                    ref={ref}
                    hidden={token}
                    className="my-2"
                    siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                    onSuccess={token => setToken(token)}
                  /> */}
                        {/* 
                  <div className="flex flex-col self-stretch gap-2" hidden={!token}> */}
                        <div className="flex flex-col self-stretch gap-2">
                          <div className="text-zinc-800 text-sm font-medium">เลือกช่องทางการชำระเงิน</div>
                          <div className={`grid grid-cols-${paymentColumns.length} gap-2`}>
                            {branchValidate?.show_promptpay && (
                              <div className="col-span-auto">
                                <Button
                                  className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                                  label={<img src={PromptpayIcon} height={20} alt="promptpay-payment" />}
                                  onClick={() => onShowModal('PROMPTPAY', true)}
                                  disabled={modalLoading || isNotRunning(machineData?.machine?.status)}
                                  loading={modalLoading}
                                />
                              </div>
                            )}

                            {branchValidate?.show_truemoney && (
                              <div className="col-span-auto">
                                <Button
                                  className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                                  label={<img src={TruemoneyIcon} height={20} alt="truemoney-payment" />}
                                  onClick={() => onShowModal('TRUEMONEY', true)}
                                  disabled={modalLoading || isNotRunning(machineData?.machine?.status)}
                                  loading={modalLoading}
                                />
                              </div>
                            )}

                            {branchValidate?.show_linepay && (
                              <div className="col-span-auto">
                                <Button
                                  className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                                  label={<img src={LinepayIcon} height={20} alt="linepay-payment" />}
                                  onClick={() => onShowModal('LINEPAY', true)}
                                  disabled={modalLoading || isNotRunning(machineData?.machine?.status)}
                                  loading={modalLoading}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <MachineNotIDLE machineType="DRY" machine={machineData} />
                  )}
                </>
              )}

              <ModalPayment
                resetRef={() => {
                  ref.current.reset()
                  setToken(null)
                }}
                token={token}
                transaction={transaction}
                selectedProgram={selectedProgram}
                machineData={machineData}
                showModal={showPaymentModal}
                setShowModal={setShowPaymentModal}
                setRefresh={setRefresh}
                incExtendDry={incExtendDry}
                provider={provider}
                branchValidate={branchValidate}
              />

              <OceanWave />
            </>
          ) : (
            <MachineNotFound machineType="DRY" />
          )}
        </>
      )}
    </div>
  )
}

export default Page
