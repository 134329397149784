import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import MachineDetailSection from '../../components/MachineDetailSection'
import ColdIcon from '../../assets/images/cold.png'
import WarmIcon from '../../assets/images/warm.png'
import HotIcon from '../../assets/images/hot.png'
import PromptpayIcon from '../../assets/images/prompt-pay.png'
import TruemoneyIcon from '../../assets/images/true-money.png'
import LinepayIcon from '../../assets/images/line-pay.png'
import { useParams } from 'react-router-dom'
import TextField from '../../components/TextField'
import { MdLocalPhone, MdClose, MdConfirmationNumber, MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md'
import MachineNotIDLE from '../../components/MachineNotIDLE'
import MachineNotFound from '../../components/MachineNotFound'
import OceanWave from '../../components/OceanWave'
import useBranch from '../../hooks/useBranch'
import Button from '../../components/Button'
import useTransaction from '../../hooks/useTransaction'
import { Turnstile } from '@marsidev/react-turnstile'
import ModalPayment from '../../components/payments/ModalPayment'
import { useNavigate } from 'react-router-dom'
import CCFavicon from '../../assets/images/cc-favicon.ico'
import WngFavicon from '../../assets/images/wng-favicon.ico'
import useConfig from '../../hooks/useConfig'

const Page = () => {
  const navigate = useNavigate()
  const { branchid, machineid } = useParams()
  const { isLoading, getBranchAndMachine, getMachineStatus, getValidateBranchByCode } = useBranch()
  const { isLoading: isLoadingConfig, getKioskConfig } = useConfig()
  const { createTransaction } = useTransaction()

  const [kioskConfig, setKioskConfig] = useState(null)
  const [settingProgram, setSettingProgram] = useState([])

  const [machineData, setMachineData] = useState(null)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [isNotFound, setNotFound] = useState(false)
  const [token, setToken] = useState(null)
  const [coupon, setCoupon] = useState('')
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')
  const [useCoupon, setUseCoupon] = useState(false)
  const ref = useRef(null)
  const [provider, setProvider] = useState('')
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [transaction, setTransaction] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)
  const [errorSelectedProgram, setErrorSelectedProgram] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [branchValidate, setBranchValidate] = useState(null)
  const [paymentColumns, setPaymentColumns] = useState([])

  const color = {
    COLD: 'bg-[#b9eaff]',
    WARM: 'bg-[#f9c3af]',
    HOT: 'bg-[#ff889c]'
  }

  const updateFavicon = iconUrl => {
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = iconUrl

    // Clear any existing favicon if present
    document.head.querySelector("link[rel='icon']")?.remove()

    // Append the new favicon link
    document.head.appendChild(link)
  }

  const getWasherData = useCallback(
    async (branchid, machineid) => {
      const data = await getBranchAndMachine(branchid, machineid)
      // console.log('🚀 ~ getWasherData ~ data:', data)
      if (data?.branch && data?.machine) {
        setMachineData(data)

        const config = await getValidateBranchByCode(data.branch.code)

        const items = []
        if (config?.show_promptpay) items.push('PROMPTPAY')
        if (config?.show_truemoney) items.push('TRUEMONEY')
        if (config?.show_linepay) items.push('LINEPAY')
        setPaymentColumns(items)
        setBranchValidate(config)

        if (config?.is_codeclean_logo) {
          updateFavicon(CCFavicon)
        } else {
          updateFavicon(WngFavicon)
        }

        if (data?.machine && data?.machine?.type !== 'WASHER') {
          setNotFound(!isNotFound)
        }

        const responseMachine = await getMachineStatus(branchid, machineid)

        const machineStatusList = responseMachine?.data || []

        const updateStatus = machineStatusList.find(item => item.machine_id === machineid)

        if (updateStatus && updateStatus?.status !== 'DISCONNECTED') {
          setMachineData(prevState => ({
            ...prevState,
            machine: {
              ...prevState?.machine,
              ...updateStatus
            }
          }))
        } else {
          setMachineData(prevState => ({
            ...prevState,
            machine: {
              ...prevState?.machine,
              machine_id: machineid,
              status: 'DISCONNECTED',
              current_transaction: '',
              temperature: 0,
              remaining_minute: 0,
              water_level: 0,
              current_program: 0,
              current_operation_step: 0,
              coin_in_cash_box: 0,
              remaining_second: 0,
              version: 0,
              updated_at: null
            }
          }))
        }
      } else {
        setNotFound(true)
      }
    },
    [getBranchAndMachine]
  )

  const fetchKioskConfig = useCallback(async () => {
    const response = await getKioskConfig('V3')
    if (response) {
      setKioskConfig(response)
    } else {
      setKioskConfig({
        enable_wash: true,
        enable_dry: true,
        enable_item: true,
        enable_extendry: true,
        enable_cash: true,
        enable_qr: true,
        enable_linepay: true,
        enable_tmn: true,
        enable_coupon: true,
        theme: 'common'
      })
    }
  }, [getKioskConfig])

  const fetchSettingProgram = useCallback(async () => {
    const response = await getKioskConfig('SETTING_PROGRAM')
    if (response) {
      setSettingProgram(response)
    } else {
      setSettingProgram([])
    }
  }, [])

  const disableProgram = useCallback((program) => {
    if (!settingProgram || !branchid) {
      return false;
    }
    const settingProgram_ = settingProgram.find(
      (setting) => setting.branch_id === branchid
    );
    return settingProgram_?.program_disabled?.includes(program) || false;
  }, [settingProgram, branchid]);

  const getOptionImage = option => {
    if (option === 'WARM') {
      return (
        <>
          <img src={WarmIcon} width={10} alt="warm-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">น้ำอุ่น</span>
        </>
      )
    } else if (option === 'HOT') {
      return (
        <>
          <img src={HotIcon} width={10} alt="hot-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">น้ำร้อน</span>
        </>
      )
    } else if (option === 'COLD') {
      return (
        <>
          <img src={ColdIcon} width={10} alt="cold-option" />{' '}
          <span className="text-neutral-800 text-sm font-semibold">น้ำเย็น</span>
        </>
      )
    }
  }

  useEffect(() => {
    if (branchid && machineid) {
      const fetchData = async () => {
        await fetchKioskConfig()
        await fetchSettingProgram()
        await getWasherData(branchid, machineid)
      }

      fetchData()
    }
    return () => {
      setMachineData(null)
      setNotFound(false)
      setSelectedProgram(null)
      setRefresh(false)
    }
  }, [branchid, machineid])

  useEffect(() => {
    if (errorSelectedProgram) {
      setTimeout(() => {
        setErrorSelectedProgram(false)
      }, 4000)
    }
  }, [errorSelectedProgram])

  useEffect(() => {
    if (refresh) {
      navigate('/thank-you')
    }
  }, [refresh])

  const onChangeCoupon = e => {
    let mask = 'XXXX-XXXX'
    let maskChar = '-'
    const value = e.target.value
    let newValue = value

    if (value.endsWith(maskChar) && e.nativeEvent.inputType === 'deleteContentBackward') {
      newValue = value.slice(0, -1) // Remove the maskChar
    } else {
      if (mask[value.length] === maskChar) {
        newValue += maskChar
      }
    }

    setCoupon(newValue.toUpperCase())
  }

  const onChangeCustomerPhonenumber = e => {
    const result = e.target.value.replace(/\D/g, '')
    setCustomerPhoneNumber(result)
  }

  const onSetUseCoupon = () => {
    setUseCoupon(!useCoupon)
    setCoupon('')
  }

  const onCreateTransaction = async () => {
    setModalLoading(true)
    let body = {
      branch_id: machineData?.branch?._id,
      machine_id: machineData?.machine?._id,
      is_offline: false,
      program: selectedProgram?.program || null,
      coupon_code: coupon || null,
      customer_phone_number: customerPhoneNumber || '',
      transaction_type: 'EXPRESS'
    }

    try {
      const response = await createTransaction(body)
      if (response?._id) {
        setTransaction(response)
      }
      throw response
    } catch (err) {
      // console.log("🚀 ~ onShowPromptPayModal ~ err:", err)
    } finally {
      setModalLoading(false)
    }
  }

  const onShowModal = async type => {
    if (!selectedProgram) {
      setErrorSelectedProgram(true)
      return
    }

    await onCreateTransaction()

    switch (type) {
      case 'PROMPTPAY':
        setProvider('promptpay')
        setShowPaymentModal(true)
        break
      case 'TRUEMONEY':
        setProvider('truemoney')
        setShowPaymentModal(true)
        break
      case 'LINEPAY':
        setProvider('linepay')
        setShowPaymentModal(true)
        break
      default:
        setProvider('')
        setShowPaymentModal(false)
        break
    }
  }

  const enableWash = useMemo(() => {
    return kioskConfig?.enable_wash
  }, [kioskConfig])

  const enableCoupon = useMemo(() => {
    return kioskConfig?.enable_coupon
  }, [kioskConfig])

  const enableQr = useMemo(() => {
    return kioskConfig?.enable_qr
  }, [kioskConfig])

  const enableLinepay = useMemo(() => {
    return kioskConfig?.enable_linepay
  }, [kioskConfig])

  const enableTruemoney = useMemo(() => {
    return kioskConfig?.enable_tmn
  }, [kioskConfig])

  return (
    <div className="relative max-w-4xl w-full h-[calc(100vh_-_160px)]">
      {isLoading || isLoadingConfig ? (
        <div className="w-full h-screen flex items-center justify-center">
          <span className="loading loading-ring loading-lg text-blue-500"></span>
        </div>
      ) : (
        <>
          {!isNotFound ? (
            <>
              {machineData?.machine?.status === 'IDLE' && enableWash ? (
                <>
                  <MachineDetailSection machineType="WASH" machine={machineData} selectedProgram={selectedProgram} />

                  <div className="absolute z-10 w-full p-6 flex flex-col self-stretch gap-6">
                    <div
                      className={`flex flex-col self-stretch gap-2 ${errorSelectedProgram ? 'p-2 animate-error-bg-pulse rounded-lg' : ''
                        }`}>
                      <div className="text-zinc-800 text-sm font-medium">เลือกอุณหภูมิ</div>
                      <div className="flex gap-2 self-stretch items-center">
                        {machineData?.machine?.pricings &&
                          machineData?.machine?.pricings.filter((pricings) => {
                            return !disableProgram(pricings.program.toUpperCase());
                          }).map((item, index) => (
                            <div
                              className={`w-full cursor-pointer rounded-md py-2.5 px-3 border ${selectedProgram?.program === item.program
                                ? color[item.program]
                                : "bg-gray-50"
                                } items-center justify-center gap-[10px] flex`}
                              key={`washing-machine-${item.program}`}
                              onClick={() => setSelectedProgram(item)}
                            >
                              {getOptionImage(item.program)}
                            </div>
                          ))}
                      </div>
                    </div>

                    {branchValidate?.is_codeclean_logo && (<div className="flex flex-col self-stretch gap-2">
                      <div className="text-zinc-800 text-sm font-medium">เบอร์โทรศัพท์</div>
                      <TextField
                        prependIcon={<MdLocalPhone className="w-4 h-4 text-gray-500" />}
                        placeholder="เบอร์โทรศัพท์"
                        value={customerPhoneNumber}
                        onChange={e => onChangeCustomerPhonenumber(e)}
                        maxLength={10}
                        appendIcon={
                          <MdClose className="w-4 h-4 text-gray-500" onClick={() => setCustomerPhoneNumber('')} />
                        }
                      />
                    </div>)}

                    {branchValidate?.enable_reward && (
                      <>
                        {enableCoupon && (
                          <div className="flex flex-col self-stretch gap-2">
                            <div className="w-fit">
                              <div
                                className="text-zinc-800 text-sm font-medium flex gap-1 items-center cursor-pointer"
                                onClick={() => onSetUseCoupon()}>
                                <MdConfirmationNumber className="w-4 h-4 text-gray-500" /> ใช้งานคูปองส่วนลด
                                <MdOutlineExpandLess className="w-4 h-4 text-gray-500" hidden={useCoupon} />
                                <MdOutlineExpandMore className="w-4 h-4 text-gray-500" hidden={!useCoupon} />
                              </div>
                            </div>
                            {useCoupon ? (
                              <div className="fade-in">
                                <div className="text-zinc-800 text-sm font-medium">รหัสคูปองส่วนลด</div>
                                <TextField
                                  prependIcon={<MdConfirmationNumber className="w-4 h-4 text-gray-500" />}
                                  placeholder="รหัสคูปองส่วนลด"
                                  value={coupon}
                                  onChange={e => onChangeCoupon(e)}
                                  maxLength={9}
                                  appendIcon={<MdClose className="w-4 h-4 text-gray-500" onClick={() => setCoupon('')} />}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </>
                    )}

                    {/* <Turnstile
                      ref={ref}
                      hidden={token}
                      className="my-2"
                      siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                      onSuccess={token => setToken(token)}
                    /> */}
                    {/* <div className="flex flex-col self-stretch gap-2" hidden={!token}> */}

                    <div className="flex flex-col self-stretch gap-2">
                      <div className="text-zinc-800 text-sm font-medium">เลือกช่องทางการชำระเงิน</div>
                      <div className={`grid grid-cols-${paymentColumns.length} gap-2`}>
                        {branchValidate?.show_promptpay && (
                          <div className="relative col-span-auto">
                            <Button
                              className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                              label={<img src={PromptpayIcon} height={20} alt="promptpay-payment" />}
                              onClick={() => onShowModal('PROMPTPAY')}
                              disabled={modalLoading || !enableQr}
                              loading={modalLoading}
                              crossedOutText={!enableQr ? 'ปิดใช้งานชั่วคราว' : null}
                            />
                          </div>
                        )}

                        {branchValidate?.show_truemoney && (
                          <div className="relative col-span-auto">
                            <Button
                              className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                              label={<img src={TruemoneyIcon} height={20} alt="truemoney-payment" />}
                              onClick={() => onShowModal('TRUEMONEY')}
                              disabled={modalLoading || !enableTruemoney}
                              loading={modalLoading}
                              crossedOutText={!enableTruemoney ? 'ปิดใช้งานชั่วคราว' : null}
                            />
                          </div>
                        )}

                        {branchValidate?.show_linepay && (
                          <div className="relative col-span-auto">
                            <Button
                              className="btn btn-default btn-block bg-gray-50 rounded-md py-2.5 px-3 border hover:bg-yellow-400"
                              label={<img src={LinepayIcon} height={20} alt="linepay-payment" />}
                              onClick={() => onShowModal('LINEPAY')}
                              disabled={modalLoading || !enableLinepay}
                              loading={modalLoading}
                              crossedOutText={!enableLinepay ? 'ปิดใช้งานชั่วคราว' : null}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <ModalPayment
                      resetRef={() => {
                        ref.current.reset()
                        setToken(null)
                      }}
                      token={token}
                      // coupon={coupon}
                      // customerPhoneNumber={customerPhoneNumber}
                      // customerPhoneNumberPoint={customerPhoneNumberPoint}
                      transaction={transaction}
                      selectedProgram={selectedProgram}
                      machineData={machineData}
                      showModal={showPaymentModal}
                      setShowModal={setShowPaymentModal}
                      provider={provider}
                      setRefresh={setRefresh}
                      branchValidate={branchValidate}
                    />
                  </div>
                </>
              ) : (
                <MachineNotIDLE machineType="WASH" machine={machineData} />
              )}

              <OceanWave />
            </>
          ) : (
            <MachineNotFound machineType="WASH" />
          )}
        </>
      )}
    </div>
  )
}

export default Page
