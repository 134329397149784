function Button(props) {
  const { type, prependIcon, appendIcon, className, label, labelClassName, disabled, loading, onClick, crossedOutText } = props

  return (
    <button type={type || 'button'} disabled={disabled || loading} className={className} onClick={onClick}>
      {loading ? (<span className="loading loading-spinner"></span>) : (
        <div className="flex self-stretch items-center justify-center gap-2">
          {prependIcon ? prependIcon : ''}
          <div className={labelClassName}>{label}</div>
          {appendIcon ? appendIcon : ''}
          {crossedOutText ? (
            <div className="absolute h-full w-full top-0 flex items-center rounded-lg bg-gray-200 bg-opacity-80 justify-center">
              <span className="text-red-600 text-md text-center font-semibold">
                {crossedOutText}
              </span>
            </div>
          ) : null}
        </div>
      )}
    </button>
  )
}

export default Button